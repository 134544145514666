require('normalize.css/normalize.css');
require('@sweetamandas/styles/common.styl');
require('./index.styl');

const form = document.querySelector('form');
const emailField = document.querySelector('input[name=email]');
const currentPasswordField = document.querySelector('input[name=currentPassword]');
const passwordField = document.querySelector('input[name=password]');
const confirmField = document.querySelector('input[name=confirmPassword]');
const passwordStrengthBar = document.querySelector('#password-strength-bar');
const passwordMessages = document.querySelector('#password-messages');
const passwordMessagesList = document.querySelector('#password-messages ul');

if (form) {
  // Make sure any hash is appended to end of referrer value
  let url = form.getAttribute('action');
  if (window.location.hash) {
    url += encodeURIComponent(window.location.hash);
  }
  form.setAttribute('action', url);
}

// Focus on email field when page loads
if (document.body.id === 'login') {
  if (!emailField.value) {
    emailField.focus();
  }
  else {
    passwordField.focus();
  }
}
else if (document.body.id === 'set-password') {
  const matchErrorEl = document.querySelector('.error.match');
  const strengthErrorEl = document.querySelector('.error.strength');

  let submitOccurred = false;
  let matchError = false;
  let strengthError = false;

  let zxcvbn;

  if (currentPasswordField) {
    currentPasswordField.focus();
  }
  else {
    passwordField.focus();
  }

  // Load zxcvbn password validation library.
  import('zxcvbn').then(z => {
    zxcvbn = z;
  });

  const updateErrors = function() {
    if (!zxcvbn) {
      return;
    }

    const results = zxcvbn(passwordField.value);
    const strengthError = results.score < 4;

    passwordStrengthBar.className = 'strength-' + results.score;

    const messages = results.feedback.suggestions;

    if (results.feedback.warning) {
      messages.unshift(results.feedback.warning);
    }


    if (strengthError) {
      passwordMessages.style.display = 'block';
      passwordMessagesList.innerHTML = '';

      // If zxcvbn doesn't give any suggestions, just add this generic one:
      if (!messages.length) {
        messages.push('Add another word or two. Uncommon words are better.');
      }

      for (let message of messages) {
        const li = document.createElement('li');
        li.innerText = message;
        passwordMessagesList.appendChild(li);
      }
    }
    else {
      passwordMessages.style.display = 'none';
      passwordMessagesList.innerHTML = '';
    }

    if (!submitOccurred) {
      return;
    }

    matchError = !strengthError && passwordField.value !== confirmField.value;

    matchErrorEl.style.display = matchError ? '' : 'none';
    strengthErrorEl.style.display = strengthError ? '' : 'none';
  };

  const form = document.querySelector('form');

  form.onsubmit = function(ev) {
    submitOccurred = true;
    updateErrors();


    if (strengthError || matchError) {
      ev.preventDefault();
      return false;
    }

    return true;
  };

  passwordField.oninput = updateErrors;
  confirmField.oninput = updateErrors;
}
else if (document.body.id === 'forgot-password') {
  if (!emailField.value) {
    emailField.focus();
  }
  else {
    passwordField.focus();
  }
}
